import React from "react"
import styled from "styled-components"
import { MainLayout } from "../layouts"

const Frame = styled.iframe`
  background: #04A46C;
  border: none;
  display: block;
  margin: 0;
  padding: 0%;
  width: 100vw;
  height: 100vh;
`

export default function(){
  return (
    <MainLayout>
      <Frame src="http://www.mobbycandidatos.com/" />
    </MainLayout>
  )
}
